table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 18px;
    text-align: left; }
  
  th,
  td {
    padding: 10px;
    border-bottom: 1px solid #ddd; }
  
  th {
    background-color: #f2f2f2; }
  
  .highlight {
    background-color: yellow; }
  
  .strong {
    background-color: #a8e6cf;
    /* Green */ }
  
  .fair {
    background-color: #fdffab;
    /* Yellow */ }
  
  .weak {
    background-color: #ff8a80;
    /* Red */ }
  
  .bg-success {
    background-color: #56d624;
    /* green, for high scores */ }
  
  .bg-warning {
    background-color: #fedc2d;
    /* yellow, for medium scores */ }
  
  .bg-danger {
    background-color: #ff5252;
    /* red, for low scores */ }
  
  /* Add other styling as needed /* GrantScoringRubric.css */
  .large-feedback-text {
    font-size: 20px;
    /* Adjust the font size as needed */ }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center; }
  
  /* Style your header container with more aesthetics */
  .header-container {
    display: flex;
    align-items: center;
    justify-content: start;
    /* Aligns content to the start of the container */
    background-color: #0E177E;
    /* Deep green background color */
    color: white;
    /* White text color for contrast */
    padding: 20px;
    /* Add some spacing around your content */
    border-radius: 5px;
    /* Rounded corners for your header */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Slight shadow for a 3D effect */
    gap: 20px;
    /* Increased gap between logo and heading */ }
  
  .header-container img {
    width: 150px;
    /* ensures the width of the image */
    height: 150px;
    /* ensures the height of the image, making it a square */
    border-radius: 50%;
    /* makes the square image a circle */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* optional: slight shadow for the image */
    object-fit: cover;
    /* ensures the image covers the frame without distortion */ }
  
  .header-container h2 {
    margin: 0;
    /* Remove browser default margins */
    padding: 0;
    font-size: 2.5em;
    /* Increase font size for readability */ }
  
  /* Style your feedback message */
  .large-feedback-text {
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #ddd;
    /* Different background from the container */
    border-radius: 5px;
    /* Consistent rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Slight shadow for a 3D effect */ }
  
  /* Add some spacing around your main content, if necessary */
  .main-content {
    margin: 20px; }
  