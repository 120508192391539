@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .btn-ani {
    @apply transform-all hover:-translate-y-0.5 duration-300 ease; }
  .btn-cta {
    @apply bg-gradient-to-r from-primary to-secondary hover:from-primary-700 hover:to-secondary-700 border-0 text-white; }
  body {
    @apply font-sans bg-white; }
  html :where(a) {
    @apply text-primary hover:text-primary-800; }
  html :where(a.text-primary) {
    @apply text-primary-600 hover:text-primary-800 active:text-primary-900; }
  html :where(a.text-secondary) {
    @apply text-secondary-600 hover:text-secondary-800 active:text-secondary-900; }
  html :where(a.text-tertiary) {
    @apply text-tertiary-600 hover:text-tertiary-800 active:text-tertiary-900; }
  html :where(a.text-success) {
    @apply text-success-500 hover:text-success-700 active:text-success-800; }
  html :where(a.text-info) {
    @apply text-info-400 hover:text-info-600 active:text-info-700; }
  html :where(a.text-warning) {
    @apply text-warning-500 hover:text-warning-700 active:text-warning-800; }
  html :where(a.text-danger) {
    @apply text-danger-600 hover:text-danger-800 active:text-danger-900; }
  html :where(a.text-light) {
    @apply text-gray-100 hover:text-gray-300 active:text-gray-400; }
  html :where(a.text-dark) {
    @apply hover:text-gray-600; }
  html :where(a.text-white) {
    @apply hover:text-gray-300; }
  h1, h2, h3, h4, h5, p {
    @apply mb-3; }
  h1, .h1 {
    @apply text-[calc(1.375rem+1.5vw)] xl:text-5xl; }
  h2, .h2 {
    @apply text-[calc(1.325rem+0.9vw)] xl:text-4xl; }
  h3, .h3 {
    @apply text-[calc(1.3rem+0.6vw)] xl:text-3xl; }
  h4, .h4 {
    @apply text-[calc(1.275rem+0.3vw)] xl:text-2xl; }
  h5, .h5 {
    @apply text-xl; }
  .display-1 {
    @apply text-[calc(1.625rem+4.5vw)] lg:text-[5rem] leading-[1.2] font-light; }
  .display-2 {
    @apply text-[calc(1.575rem+3.9vw)] lg:text-[4.5rem] leading-[1.2] font-light; }
  .display-3 {
    @apply text-[calc(1.525rem+3.3vw)] lg:text-[4rem] leading-[1.2] font-light; }
  .display-4 {
    @apply text-[calc(1.475rem+2.7vw)] lg:text-[3.5rem] leading-[1.2] font-light; }
  .display-5 {
    @apply text-[calc(1.425rem+2.1vw)] lg:text-[3rem] leading-[1.2] font-light; }
  .display-6 {
    @apply text-[calc(1.375rem+1.6vw)] lg:text-[2.5rem] leading-[1.2] font-light; }
  .accordion-item {
    @apply flex flex-col bg-white rounded-lg p-4 mb-3; }
  .accordion-header {
    @apply flex items-center text-xl w-full cursor-pointer font-semibold text-dark hover:text-primary-700 transition-all duration-300 after:transition after:duration-300 after:flex-shrink-0 after:w-5 after:h-5 after:ml-auto after:bg-arrow-down after:content-['']; }
  .accordion-header[aria-expanded="true"] {
    @apply text-primary hover:text-primary-700 after:rotate-180; }
  .accordion-collapse {
    @apply transition-all duration-300; }
  .accordion-body {
    @apply py-3; }
  .alert {
    @apply flex flex-row rounded-md px-3 py-2 text-gray-700 bg-gray-100 transition-opacity duration-300; }
  .badge {
    @apply inline-block px-2 py-0.5 rounded text-xs text-white font-semibold; }
  .badge-lg {
    @apply px-3 py-1 rounded text-sm text-white font-semibold; }
  .btn {
    @apply inline-flex flex-wrap items-center justify-center text-center cursor-pointer select-none transition duration-200 ease-in-out flex-shrink-0 font-semibold py-2 px-4 rounded-md disabled:opacity-80 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-opacity-50 hover:no-underline border-0; }
  .btn-outline.btn-primary {
    @apply bg-transparent text-primary-600 hover:bg-primary-700 active:bg-primary-800 active:text-white hover:text-white border-primary-600 border focus:ring-primary-800; }
  .btn-outline.btn-secondary {
    @apply bg-transparent text-secondary-600 hover:bg-secondary-700 active:bg-secondary-800 active:text-white hover:text-white border-secondary-600 border focus:ring-secondary-800; }
  .btn-outline.btn-tertiary {
    @apply bg-transparent text-tertiary-600 hover:bg-tertiary-700 active:bg-tertiary-800 active:text-white hover:text-white border-tertiary-600 border focus:ring-tertiary-800; }
  .btn-outline.btn-success {
    @apply bg-transparent text-success-500 hover:bg-success-600 active:bg-success-700 active:text-white hover:text-white border-success-500 border focus:ring-success-700; }
  .btn-outline.btn-info {
    @apply bg-transparent text-info-400 hover:bg-info-500 active:bg-info-600 active:text-white hover:text-white border-info-400 border focus:ring-info-600; }
  .btn-outline.btn-warning {
    @apply bg-transparent text-warning-500 hover:bg-warning-600 active:bg-warning-700 active:text-white hover:text-white border-warning-500 border focus:ring-warning-700; }
  .btn-outline.btn-danger {
    @apply bg-transparent text-danger-600 hover:bg-danger-700 active:bg-danger-800 active:text-white hover:text-white border-danger-600 border focus:ring-danger-800; }
  .btn-outline.btn-light {
    @apply bg-transparent text-gray-100 hover:bg-gray-200 active:bg-gray-300 active:text-white hover:text-white border-gray-100 border focus:ring-gray-300; }
  .btn-outline.btn-dark {
    @apply bg-transparent text-gray-800 hover:bg-gray-900 active:bg-gray-800 active:text-white hover:text-white border-gray-800 border focus:ring-gray-800; }
  .btn-outline.btn-white {
    @apply bg-transparent text-white active:text-white hover:text-white border-white border; }
  .btn-outline.btn-black {
    @apply bg-transparent text-black active:text-white hover:text-white border-black border; }
  .btn-outline.btn-muted {
    @apply bg-transparent text-gray-700 active:text-white hover:text-white border-gray-700 border; }
  .btn-subtle.btn-primary {
    @apply bg-transparent bg-primary-100 hover:bg-primary-200 hover:text-primary-700 active:bg-primary-300 text-primary-600 border-0 focus:ring-primary-800; }
  .btn-subtle.btn-secondary {
    @apply bg-transparent bg-secondary-100 hover:bg-secondary-200 hover:text-secondary-700 active:bg-secondary-300 text-secondary-600 border-0 focus:ring-secondary-800; }
  .btn-subtle.btn-tertiary {
    @apply bg-transparent bg-tertiary-100 hover:bg-tertiary-200 hover:text-tertiary-700 active:bg-tertiary-300 text-tertiary-600 border-0 focus:ring-tertiary-800; }
  .btn-subtle.btn-success {
    @apply bg-transparent bg-success-100 hover:bg-success-200 hover:text-success-600 active:bg-success-300 text-success-500 border-0 focus:ring-success-700; }
  .btn-subtle.btn-info {
    @apply bg-transparent bg-info-100 hover:bg-info-200 hover:text-info-500 active:bg-info-300 text-info-400 border-0 focus:ring-info-600; }
  .btn-subtle.btn-warning {
    @apply bg-transparent bg-warning-100 hover:bg-warning-200 hover:text-warning-600 active:bg-warning-300 text-warning-500 border-0 focus:ring-warning-700; }
  .btn-subtle.btn-danger {
    @apply bg-transparent bg-danger-100 hover:bg-danger-200 hover:text-danger-700 active:bg-danger-300 text-danger-600 border-0 focus:ring-danger-800; }
  .btn-subtle.btn-light {
    @apply bg-transparent bg-gray-100 hover:bg-gray-200 hover:text-gray-200 active:bg-gray-300 text-gray-100 border-0 focus:ring-gray-300; }
  .btn-subtle.btn-dark {
    @apply bg-transparent bg-gray-100 hover:bg-gray-200 hover:text-gray-900 active:bg-gray-300 text-gray-800 border-0 focus:ring-gray-800; }
  .btn-subtle.btn-white {
    @apply bg-transparent text-white border-0; }
  .btn-subtle.btn-black {
    @apply bg-transparent text-black border-0; }
  .btn-subtle.btn-muted {
    @apply bg-transparent text-gray-700 border-0; }
  .btn-link.btn-primary {
    @apply bg-transparent hover:bg-transparent active:bg-transparent border-transparent text-primary-600 hover:text-primary-800 active:text-primary-900 border-0 hover:underline focus:ring-primary-800; }
  .btn-link.btn-secondary {
    @apply bg-transparent hover:bg-transparent active:bg-transparent border-transparent text-secondary-600 hover:text-secondary-800 active:text-secondary-900 border-0 hover:underline focus:ring-secondary-800; }
  .btn-link.btn-tertiary {
    @apply bg-transparent hover:bg-transparent active:bg-transparent border-transparent text-tertiary-600 hover:text-tertiary-800 active:text-tertiary-900 border-0 hover:underline focus:ring-tertiary-800; }
  .btn-link.btn-success {
    @apply bg-transparent hover:bg-transparent active:bg-transparent border-transparent text-success-500 hover:text-success-700 active:text-success-800 border-0 hover:underline focus:ring-success-700; }
  .btn-link.btn-info {
    @apply bg-transparent hover:bg-transparent active:bg-transparent border-transparent text-info-400 hover:text-info-600 active:text-info-700 border-0 hover:underline focus:ring-info-600; }
  .btn-link.btn-warning {
    @apply bg-transparent hover:bg-transparent active:bg-transparent border-transparent text-warning-500 hover:text-warning-700 active:text-warning-800 border-0 hover:underline focus:ring-warning-700; }
  .btn-link.btn-danger {
    @apply bg-transparent hover:bg-transparent active:bg-transparent border-transparent text-danger-600 hover:text-danger-800 active:text-danger-900 border-0 hover:underline focus:ring-danger-800; }
  .btn-link.btn-light {
    @apply bg-transparent hover:bg-transparent active:bg-transparent border-transparent text-gray-100 hover:text-gray-300 active:text-gray-400 border-0 hover:underline focus:ring-gray-300; }
  .btn-link.btn-dark {
    @apply bg-transparent hover:bg-transparent active:bg-transparent border-transparent text-gray-800 hover:text-gray-900 active:text-gray-700 border-0 hover:underline focus:ring-gray-800; }
  .btn-link.btn-white {
    @apply bg-transparent hover:bg-transparent active:bg-transparent border-transparent border-0 hover:underline; }
  .btn-link.btn-black {
    @apply bg-transparent hover:bg-transparent active:bg-transparent border-transparent border-0 hover:underline; }
  .btn-link.btn-muted {
    @apply bg-transparent hover:bg-transparent active:bg-transparent border-transparent border-0 hover:underline; }
  .btn-ghost.btn-primary {
    @apply bg-transparent hover:bg-primary-100 hover:text-primary-700 active:bg-primary-200 text-primary-600 border-0 focus:ring-primary-800; }
  .btn-ghost.btn-secondary {
    @apply bg-transparent hover:bg-secondary-100 hover:text-secondary-700 active:bg-secondary-200 text-secondary-600 border-0 focus:ring-secondary-800; }
  .btn-ghost.btn-tertiary {
    @apply bg-transparent hover:bg-tertiary-100 hover:text-tertiary-700 active:bg-tertiary-200 text-tertiary-600 border-0 focus:ring-tertiary-800; }
  .btn-ghost.btn-success {
    @apply bg-transparent hover:bg-success-100 hover:text-success-600 active:bg-success-200 text-success-500 border-0 focus:ring-success-700; }
  .btn-ghost.btn-info {
    @apply bg-transparent hover:bg-info-100 hover:text-info-500 active:bg-info-200 text-info-400 border-0 focus:ring-info-600; }
  .btn-ghost.btn-warning {
    @apply bg-transparent hover:bg-warning-100 hover:text-warning-600 active:bg-warning-200 text-warning-500 border-0 focus:ring-warning-700; }
  .btn-ghost.btn-danger {
    @apply bg-transparent hover:bg-danger-100 hover:text-danger-700 active:bg-danger-200 text-danger-600 border-0 focus:ring-danger-800; }
  .btn-ghost.btn-light {
    @apply bg-transparent hover:bg-gray-100 hover:text-gray-200 active:bg-gray-200 text-gray-100 border-0 focus:ring-gray-300; }
  .btn-ghost.btn-dark {
    @apply bg-transparent hover:bg-pink-200 hover:text-gray-800 text-gray-800 border-0 focus:ring-gray-800; }
  .btn-ghost.btn-white {
    @apply bg-transparent text-white border-0; }
  .btn-ghost.btn-black {
    @apply bg-transparent text-black border-0; }
  .btn-ghost.btn-muted {
    @apply bg-transparent text-gray-700 border-0; }
  .btn-ghost.btn-light:not(.c) {
    @apply text-gray-900; }
  .btn-xs, .btn-group-xs > .btn {
    @apply py-1 px-1 text-xs; }
  .btn-sm, .btn-group-sm > .btn {
    @apply py-1 px-2 text-sm; }
  .btn-lg, .btn-group-lg > .btn {
    @apply py-3 px-5 text-lg; }
  .btn-xl, .btn-group-xl > .btn {
    @apply py-4 px-6 text-xl; }
  .btn-group {
    @apply inline-flex; }
  .btn-group .btn {
    @apply rounded-none first:rounded-l-md last:rounded-r-md; }
  .btn-group > .btn-outline:not(:first-child) {
    @apply border-l-0 border-r-[1px]; }
  .pagination > .btn {
    @apply px-3 py-1; }
  .btn-outline.btn-primary[aria-current="page"] {
    @apply bg-primary-600 text-white; }
  .btn-outline.btn-secondary[aria-current="page"] {
    @apply bg-secondary-600 text-white; }
  .btn-outline.btn-tertiary[aria-current="page"] {
    @apply bg-tertiary-600 text-white; }
  .btn-outline.btn-success[aria-current="page"] {
    @apply bg-success-500 text-white; }
  .btn-outline.btn-info[aria-current="page"] {
    @apply bg-info-400 text-white; }
  .btn-outline.btn-warning[aria-current="page"] {
    @apply bg-warning-500 text-white; }
  .btn-outline.btn-danger[aria-current="page"] {
    @apply bg-danger-600 text-white; }
  .btn-outline.btn-light[aria-current="page"] {
    @apply bg-gray-100 text-black; }
  .btn-outline.btn-dark[aria-current="page"] {
    @apply bg-gray-800 text-white; }
  .btn-outline.btn-white[aria-current="page"] {
    @apply bg-white text-black; }
  .btn-outline.btn-black[aria-current="page"] {
    @apply bg-black text-white; }
  .btn-outline.btn-muted[aria-current="page"] {
    @apply bg-gray-700; }
  .btn-ghost.btn-primary[aria-current="page"] {
    @apply hover:text-primary-700 bg-primary-100 text-primary-600; }
  .btn-ghost.btn-secondary[aria-current="page"] {
    @apply hover:text-secondary-700 bg-secondary-100 text-secondary-600; }
  .btn-ghost.btn-tertiary[aria-current="page"] {
    @apply hover:text-tertiary-700 bg-tertiary-100 text-tertiary-600; }
  .btn-ghost.btn-success[aria-current="page"] {
    @apply hover:text-success-600 bg-success-100 text-success-500; }
  .btn-ghost.btn-info[aria-current="page"] {
    @apply hover:text-info-500 bg-info-100 text-info-400; }
  .btn-ghost.btn-warning[aria-current="page"] {
    @apply hover:text-warning-600 bg-warning-100 text-warning-500; }
  .btn-ghost.btn-danger[aria-current="page"] {
    @apply hover:text-danger-700 bg-danger-100 text-danger-600; }
  .btn-ghost.btn-light[aria-current="page"] {
    @apply hover:text-gray-200 bg-gray-100 text-gray-100; }
  .btn-ghost.btn-dark[aria-current="page"] {
    @apply hover:text-gray-800 bg-pink-200 text-gray-800; }
  .btn-ghost.btn-white[aria-current="page"] {
    @apply text-white; }
  .btn-ghost.btn-black[aria-current="page"] {
    @apply text-black; }
  .btn-ghost.btn-muted[aria-current="page"] {
    @apply text-gray-700; }
  .modal {
    @apply fixed left-0 top-0 w-screen h-screen overflow-auto z-50 transition-opacity duration-500 opacity-0; }
  .modal-bg {
    @apply fixed w-screen h-screen left-0 top-0 z-30 pt-16 bg-gray-900 overflow-auto transition-opacity duration-500 opacity-50; }
  .modal-content {
    @apply relative m-auto mt-16 bg-gray-100 shadow-lg z-40 max-w-md; }
  .modal-lg {
    @apply max-w-screen-md; }
  .modal-sm {
    @apply max-w-sm; }
  .modal-xl {
    @apply max-w-screen-xl; }
  .disabled {
    @apply opacity-80 pointer-events-none; }
  .form-checkbox {
    @apply rounded border-primary-300 text-primary-600 shadow-sm focus:border-primary-300 focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50; }
  .form-checkbox[type="checkbox"] {
    @apply rounded; }
  .form-radio[type="radio"] {
    @apply text-primary-600 ring-offset-2 focus:ring-2 focus:ring-primary-300; }
  .form-input {
    @apply block mt-0 w-full rounded-md border-gray-300 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50; }
  .form-select {
    @apply block mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50; }
  .form-input-sm {
    @apply px-2 py-1 text-sm; }
  .form-input-lg {
    @apply px-4 py-2 text-lg; }
  .form-select-sm {
    @apply pl-2 py-1 text-sm; }
  .form-select-lg {
    @apply pl-4 py-2 text-lg; }
  .navbar {
    @apply z-20 py-3 relative; }
  .navbar > .container-fluid, .navbar > .container {
    @apply flex flex-wrap justify-between md:flex-nowrap px-3 md:px-5; }
  .navbar-dark .navbar-btn {
    @apply focus:ring-white; }
  .navbar-btn {
    @apply w-6 mr-5 cursor-pointer select-none rounded md:hidden; }
  .navbar-brand {
    @apply flex flex-row items-center space-x-3 ml-5 md:ml-0 text-primary font-bold text-xl; }
  .navbar-col {
    @apply flex flex-col items-center mt-3 space-y-4 md:flex-row md:space-x-4 md:space-y-0 md:mt-0; }
  .navbar-row {
    @apply hidden w-screen items-center md:flex md:flex-grow md:justify-between md:w-auto md:ml-4 transition-all transform duration-300 opacity-0 h-0 md:opacity-100 md:overflow-visible; }
  @media (min-width: 768px) {
    /* This assumes the 'md' breakpoint is at 768px */
    .navbar-row {
      height: auto !important;
      /* This manually sets the 'h-auto' property with the '!important' flag at the 'md' breakpoint */ } }
  .navbar-row.show {
    @apply opacity-100; }
  .dropdown-menu {
    @apply hidden transition duration-200 opacity-0 static w-max py-2 bg-white border border-gray-300 rounded md:absolute mt-2 min-w-[160px]; }
  .dropdown-item {
    @apply bg-transparent py-1.5 px-4 hover:text-primary-800 focus:text-primary-800 hover:bg-primary-100 focus:bg-primary-100 focus:outline-none focus:shadow-outline; }
  .dropdown-menu.show {
    @apply opacity-100 block; }
  [data-toggle="dropdown"] {
    @apply flex items-center gap-x-1 after:flex-shrink-0 after:w-4 after:h-4 after:bg-caret-down after:content-[''] after:bg-no-repeat; }
  .row {
    @apply flex flex-wrap flex-row -mx-5; }
  .col {
    @apply relative w-full px-5; }
  .progress {
    @apply flex w-full overflow-hidden bg-gray-200 rounded-full h-2.5; }
  .progress-bar {
    @apply h-full; }
  .progress-sm {
    @apply h-1.5; }
  .progress-lg {
    @apply h-4; }
  .progress-xl {
    @apply h-6; }
  .card {
    @apply relative flex flex-col overflow-hidden shadow-md rounded-md bg-white; }
  .card-body {
    @apply p-5 flex-grow; }
  .card-header {
    @apply px-5 py-3 bg-gray-100; }
  .card-footer {
    @apply px-5 py-3 bg-gray-100; }
  .tab-list {
    @apply flex flex-wrap gap-x-2 mb-2 select-none; }
  .tab-content {
    @apply py-2; }
  .tab {
    @apply px-4 py-2 border-b-2 hover:text-primary ring-0 focus:outline-none; }
  .tab[aria-selected="true"] {
    @apply border-primary text-primary; }
  .tab-list-underline {
    @apply border-b-2 border-gray-300; }
  .tab-underline {
    @apply px-3 py-2 text-muted hover:text-primary focus:outline-none; }
  .tab-underline[aria-selected="true"] {
    @apply -mb-1 border-0 border-b-2 border-primary text-primary; }
  .table {
    @apply min-w-full; }
  html :where(.table > thead) {
    @apply bg-gray-50; }
  html :where(.table > thead > tr > th) {
    @apply py-3 px-4 text-xs font-semibold text-left text-gray-700; }
  .table > tbody > tr {
    @apply bg-white border-b; }
  html :where(.table > tbody > tr > td) {
    @apply py-3 px-4 text-sm text-gray-900; }
  .table-sm > thead > tr > th {
    @apply py-1; }
  .table-sm > tbody > tr > td {
    @apply py-1; }
  .table-striped > thead > tr {
    @apply bg-gray-50 border-b border-gray-300; }
  .table-striped > tbody > tr {
    @apply even:bg-gray-100; }
  .table-hover > tbody > tr {
    @apply hover:bg-gray-200; }
  .prose > blockquote {
    @apply border-l-4 border-gray-600 my-3 py-3 pl-4 text-xl -ml-5; }
  .prose a {
    @apply text-primary hover:text-primary-700; }
  .prose ul ul {
    @apply list-circle; }
  .prose ul ul ul {
    @apply list-square; }
  .prose h4 {
    @apply text-lg; }
  .fa-ul {
    @apply ml-6; }
  .container {
    @apply w-full max-w-full lg:max-w-[calc(min(100vw-60px,1400px))] mx-auto px-5; }
  .container-fluid {
    @apply w-full max-w-full mx-auto px-5; }
  .alert-primary {
    @apply text-primary-600 bg-primary-50; }
  .alert-secondary {
    @apply text-secondary-600 bg-secondary-50; }
  .alert-tertiary {
    @apply text-tertiary-600 bg-tertiary-50; }
  .alert-success {
    @apply text-success-600 bg-success-50; }
  .alert-info {
    @apply text-info-600 bg-info-50; }
  .alert-warning {
    @apply text-warning-600 bg-warning-50; }
  .alert-danger {
    @apply text-danger-600 bg-danger-50; }
  .alert-light {
    @apply text-gray-600 bg-gray-50; }
  .btn-primary {
    @apply bg-primary-600 hover:bg-primary-700 hover:text-white active:bg-primary-800 text-white border-primary-600 hover:border-primary-700 ring-primary-600 focus:ring-primary-800; }
  .btn-secondary {
    @apply bg-secondary-600 hover:bg-secondary-700 hover:text-white active:bg-secondary-800 text-white border-secondary-600 hover:border-secondary-700 ring-secondary-600 focus:ring-secondary-800; }
  .btn-tertiary {
    @apply bg-tertiary-600 hover:bg-tertiary-700 hover:text-white active:bg-tertiary-800 text-white border-tertiary-600 hover:border-tertiary-700 ring-tertiary-600 focus:ring-tertiary-800; }
  .btn-success {
    @apply bg-success-500 hover:bg-success-600 hover:text-white active:bg-success-700 text-white border-success-500 hover:border-success-600 ring-success-500 focus:ring-success-700; }
  .btn-info {
    @apply bg-info-400 hover:bg-info-500 hover:text-white active:bg-info-600 text-white border-info-400 hover:border-info-500 ring-info-400 focus:ring-info-600; }
  .btn-warning {
    @apply bg-warning-500 hover:bg-warning-600 hover:text-white active:bg-warning-700 text-white border-warning-500 hover:border-warning-600 ring-warning-500 focus:ring-warning-700; }
  .btn-danger {
    @apply bg-danger-600 hover:bg-danger-700 hover:text-white active:bg-danger-800 text-white border-danger-600 hover:border-danger-700 ring-danger-600 focus:ring-danger-800; }
  .btn-light {
    @apply bg-gray-100 hover:bg-gray-200 hover:text-black active:bg-gray-300 text-black border-gray-100 hover:border-gray-200 ring-gray-100 focus:ring-gray-300; }
  .btn-dark {
    @apply bg-gray-800 hover:bg-gray-900 hover:text-white active:bg-gray-800 text-white border-gray-800 hover:border-gray-900 ring-gray-800 focus:ring-gray-800; }
  .btn-white {
    @apply bg-white text-black border-white ring-white; }
  .btn-black {
    @apply bg-black text-white border-black ring-black; }
  .btn-muted {
    @apply bg-gray-700 border-gray-700 ring-gray-700; } }
