.agreement {
    font-family: 'Arial', sans-serif;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .agreement h1 {
    color: #333;
    text-align: center;
  }
  
  .agreement h2 {
    color: #555;
  }
  
  .agreement p {
    text-align: left;
    line-height: 1.6;
    color: #666;
  }
  